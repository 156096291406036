/* globals ngettext, interpolate, htmx */
import { qs, qsa } from "/js/utils/dom"

export const initMap = () => {
  const mapDataRaw = qs("#map-data")

  if (mapDataRaw) {
    const mapData = JSON.parse(mapDataRaw.textContent)
    const mapElement = qs(".map")

    //get highest number of films per country and distribute
    let filmCountMaxValue = 0
    const filmCountObject = Object.values(mapData)
    filmCountObject.map((el) => {
      filmCountMaxValue = Math.max(filmCountMaxValue, el.film_count)
    })
    const colorBreakNumber = filmCountMaxValue / 9

    //manipulate the DOM
    const countries = qsa(".map__country")

    if (countries) {
      countries.addEventListener

      countries.forEach((country) => {
        const countryCode = country.dataset.code
        const countryObject = mapData[countryCode]
        if (!countryObject) return
        const colorNumber = Math.ceil(
          countryObject.film_count / colorBreakNumber,
        )

        country.setAttribute("fill", ` var(--film-count-color-${colorNumber})`)
        country.dataset.name = countryObject.name
        country.dataset.film_count = countryObject.film_count

        if (colorNumber > 0) {
          country.setAttribute("role", "button")
          country.dataset.url = countryObject.url
        }
      })
    }

    const tooltip = qs(".map-tooltip")
    const tooltipName = qs(".map-tooltip__name")
    const tooltipFilmCount = qs(".map-tooltip__film-count")

    let xPos = 0
    let yPos = 0
    mapElement.addEventListener("mouseover", (event) => {
      let countryTarget = event.target.closest(".map__country[data-name]")
      if (countryTarget) {
        tooltip.setAttribute("aria-hidden", "false")
        tooltipName.textContent = countryTarget.dataset.name
        tooltipFilmCount.textContent = interpolate(
          ngettext(
            "%s trigon-film",
            "%s trigon-films",
            countryTarget.dataset.film_count,
          ),
          [countryTarget.dataset.film_count],
        )
      } else {
        tooltip.setAttribute("aria-hidden", "true")
      }
    })

    mapElement.addEventListener("mousemove", (event) => {
      let countryTarget = event.target.closest(".map__country")
      if (!countryTarget) return

      xPos = event.layerX
      yPos = event.layerY
      //XXX: is this correct calling of requestAnimationFrame
      requestAnimationFrame(() => {
        tooltip.style.left = `${xPos}px`
        tooltip.style.top = `${yPos}px`
      })
    })

    mapElement.addEventListener("click", (event) => {
      const countryTarget = event.target.closest(".map__country")
      if (!countryTarget) return

      const filterForm = document.querySelector(".film-list .filter--form")
      if (filterForm) {
        const filter = filterForm.querySelector("[name=country]")
        filter.value = countryTarget.dataset.name
        htmx.trigger(filter, "input")
        filterForm.scrollIntoView()
      } else {
        const countryTargetUrl = countryTarget.dataset.url
        if (countryTargetUrl) {
          window.location.href = countryTargetUrl
        }
      }
    })
  }
}
