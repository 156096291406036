export function setDetailsHeights(detailElements) {
  const setHeights = (details) => {
    const initiallyOpen = details.open
    details.dataset.width = details.offsetWidth
    details.open = true
    details.style.setProperty("--expanded", `${details.offsetHeight}px`)
    details.open = false
    details.style.setProperty("--collapsed", `${details.offsetHeight}px`)
    details.open = initiallyOpen
  }
  const RO = new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) return

      return entries.forEach((entry) => {
        const details = entry.target
        const width = parseInt(details.dataset.width, 10)
        if (width !== details.offsetWidth) {
          details.removeAttribute("style")
          setHeights(details)
        }
      })
    })
  })
  detailElements.forEach((details) => {
    RO.observe(details)
  })
}
